import * as React from "react";
import {
    Button,
    Container,
    Divider,
    Dropdown,
    DropdownProps,
    Form,
    Header,
    Icon,
    Image,
    InputOnChangeData,
    List,
    Popup,
    Segment,
    Table
} from "semantic-ui-react";
import {SimulationPageProps} from "../simulation/SimulationPageProps";
import {ResourceInfo} from "../../../models/ResourceInfo";
import {solveService} from "../../../services/solve.service";
import ApplicationState from "../../../state/ApplicationState";
import {ThunkDispatch} from "redux-thunk";
import {errorResponse} from "action-alert";
import {connect} from "react-redux";
import NewSolveModal from "./NewSolveModal";
import {SolveData} from "../../../models/Solve";
import {Link} from "react-router-dom";
import {solveActions} from "../../../actions/solve.actions";
import * as FileSaver from "file-saver";
import {MonitorPara} from "../../../models/Simulation";
import {NumberInput} from "../../inputs/NumberInput";

interface LinkProps extends SimulationPageProps{
    baseUrl:string
}

interface StateProps{

    //Store a list of current solves
    solveList?:SolveData[]
}

interface DispatchProps {
    errorResponse:(error:any) =>{}
    updateSolvesForSim:(simId:any) =>{}
}

interface NewSolveListState {
    //Store the list of resources
    availableResources?: ResourceInfo[]

    //Store the activeResource ResourceInfo
    activeResource?:ResourceInfo

    //Store an activeDownload
    activeDownload:number[];

    //Store monitorpara
    monitorPara?:MonitorPara
}

const dropdownVariables = [
    {
        key: 'CCVelocity',
        text: 'Velocity',
        value: 'CCVelocity'
    },
    {
        key: 'temperature',
        text: 'Temperature',
        value: 'temperature'
    }
]


/**
 * This card shows the animal details
 */
class SolveSummary extends React.Component<LinkProps&DispatchProps&StateProps, NewSolveListState> {
    state = {
        availableResources: undefined,
        activeResource:undefined,
        solveList:undefined,
        activeDownload: [] as number[],
        monitorPara:this.props.sim.parameters.monitorpara
    }

    //Download the log
    downloadPackage = (solInfo:SolveData) =>{
        //Set the active download
        this.setState({activeDownload:[...this.state.activeDownload, solInfo.id]})

        //Get the data
        solveService.downloadSolvePackage(solInfo.id ).then(
            //If successful html will be returned
            blob => {
                //Update the state
                FileSaver.saveAs(blob, solInfo.name + " " + solInfo.id);

                //Now remove
                this.setState({activeDownload: this.state.activeDownload.filter(id => id != solInfo.id)})
            },
            //If there was an error, show to the user
            errorResponse => {
                //Dispatch the error
                alert(errorResponse)

                //Now remove
                this.setState({activeDownload: this.state.activeDownload.filter(id => id != solInfo.id)})
            }
        )
    }

    /**
     *   Keep locally to allow it to be removed from mem
     */
    componentDidMount(){
        //If the user is logged in get the logged in
        let resListPromise = solveService.getSolveResources(this.props.sim.id)

        //When it comes back use it
        resListPromise.then(
            //If successful html will be returned
            list => {
                //Update the state
                this.setState({availableResources:list})
            },
            //If there was an error, show to the user
            errorResponse => {
               this.props.errorResponse(errorResponse);

            }
        );
        //Also update the list of solves
        this.props.updateSolvesForSim(this.props.sim.id)
    };

    stopSolve = (solveId:number) =>{
        solveService.stopSolve(solveId).then(() =>{
            this.props.updateSolvesForSim(this.props.sim.id);
        }).catch(err =>{
            this.props.errorResponse(err);
        })
    }

    buildProviderList(){
        //Now return the table
        if(this.state.availableResources) {
            //Get the list of resources
            const list = this.state.availableResources! as ResourceInfo[];

            return (
                <List selection>
                    {list.map(info =>{
                        return (
                            <List.Item
                                key={info.resourceId}
                                active={info==this.state.activeResource}
                                onClick={() => this.setState({activeResource:info})}
                            >
                                <Image avatar src={info.imgUrl} />
                                <List.Content>
                                    <List.Header>{info.name}</List.Header>
                                    <List.Description>
                                        {info.description}
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        );
                    })}
                </List>
            );
        }else{
            return (
                "loading"
            );
        }
    }

    buildSolveTable(){
        //Now return the table
        if(this.props.solveList) {
            //Get the list of resources
            const list = this.props.solveList! as SolveData[];

            return (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Creation</Table.HeaderCell>
                            <Table.HeaderCell>Last Update</Table.HeaderCell>
                            <Table.HeaderCell>Provider</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {list.map(solve =>{
                            return (
                                <Table.Row >
                                    <Table.Cell>
                                        <Link to={`${this.props.baseUrl}/${solve.id}`}>
                                            <Icon
                                                name='edit'
                                            />
                                            {solve.name}
                                        </Link>

                                    </Table.Cell>
                                    <Table.Cell>
                                        {solve.status}
                                        {isStoppable(solve.status) &&
                                            <Icon
                                                name='stop circle'
                                                onClick={() => this.stopSolve(solve.id)}
                                            />
                                        }
                                    </Table.Cell>
                                    <Table.Cell>{solve.creationTime}</Table.Cell>
                                    <Table.Cell>{solve.lastUpdate}</Table.Cell>
                                    <Table.Cell>{solve.resource.resourceId}</Table.Cell>
                                    <Table.Cell>
                                        {this.state.activeDownload.indexOf(solve.id)  < 0 &&
                                            <Icon
                                                name='download'
                                                onClick={() => this.downloadPackage(solve)}
                                            />
                                        }
                                        {this.state.activeDownload.indexOf(solve.id) > -1 &&
                                            <Icon
                                                name='spinner'
                                            />
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            );
        }else{
            return (
                "loading"
            );
        }
    }

    saveChanges = (requiredList:{}) =>{
        // First check if all fields are ready
        if(this.state.monitorPara != undefined) {
            let submitCopy = this.props.sim;
            submitCopy.parameters.monitorpara = this.state.monitorPara!;
            this.props.updateSimulation(submitCopy)
        }
        //And cancel
        this.cancelChanges()
    };

    cancelChanges =() =>{
        this.setState({monitorPara:undefined});
    };

    handleDropdown = (e:any, {value, mon}:any) =>{
        if(value===undefined)
            console.log("undefined");
        else{
            console.log(value)
            console.log(JSON.stringify(mon))
            this.setState({monitorPara:{...mon, selVar:parseFloat(value)}})
        }
    }

    render(): React.ReactNode {
        //Get the pull copy
        let mon: any
        if (this.state.monitorPara != undefined)
            mon = this.state.monitorPara!;
        else if(this.props.sim.parameters.monitorpara != undefined)
            mon = this.props.sim.parameters.monitorpara;
        else
            mon = {
                beginX: "", beginY: "", beginZ: "",
                endX: "", endY: "", endZ: "",
                selVar: ""
        }


        return (
            <Container style={{ padding: '5em 0em' }}>
                <Header as='h2'>Resources</Header>
                <Segment attached>
                    {this.buildProviderList()}
                </Segment>
                <Segment attached='bottom'>
                    <NewSolveModal
                        key={this.state.activeResource}
                        activeResource={this.state.activeResource}
                        sim={this.props.sim}
                    />
                </Segment>
                <Header as={'h2'}>Monitor Setup</Header>
                <Segment>
                    <Header as={'h3'}>Selected Location (m)</Header>
                    <Form widths={'equal'}>
                        <Form.Group inline>
                            <Form.Field>
                                <label>Begin</label>
                                <Popup
                                    trigger={
                                        <NumberInput
                                            style={{width: "100px"}}
                                            placeholder='x'
                                            value={mon.beginX}
                                            onChange={(v, data: InputOnChangeData) =>
                                                this.setState({monitorPara: {...mon, beginX: parseFloat(data.value)}})}
                                        />
                                    }
                                    content={"X"}
                                />
                                <Popup
                                    trigger={
                                        <NumberInput
                                            style={{width: "100px"}}
                                            placeholder='y'
                                            value={mon.beginY}
                                            onChange={(v, data: InputOnChangeData) =>
                                                this.setState({monitorPara: {...mon, beginY: parseFloat(data.value)}})}
                                        />
                                    }
                                    content={"Y"}
                                />
                                <Popup
                                    trigger={
                                        <NumberInput
                                            style={{width: "100px"}}
                                            placeholder='z'
                                            value={mon.beginZ}
                                            onChange={(v, data: InputOnChangeData) =>
                                                this.setState({monitorPara: {...mon, beginZ: parseFloat(data.value)}})}
                                        />
                                    }
                                    content={"Z"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>End</label>
                                <Popup
                                    trigger={
                                        <NumberInput
                                            style={{width: "100px"}}
                                            placeholder='x'
                                            value={mon.endX}
                                            onChange={(v, data: InputOnChangeData) =>
                                                this.setState({monitorPara: {...mon, endX: parseFloat(data.value)}})}
                                        />
                                    }
                                    content={"X"}
                                />
                                <Popup
                                    trigger={
                                        <NumberInput
                                            style={{width: "100px"}}
                                            placeholder='y'
                                            value={mon.endY}
                                            onChange={(v, data: InputOnChangeData) =>
                                                this.setState({monitorPara: {...mon, endY: parseFloat(data.value)}})}
                                        />
                                    }
                                    content={"Y"}
                                />
                                <Popup
                                    trigger={
                                        <NumberInput
                                            style={{width: "100px"}}
                                            placeholder='z'
                                            value={mon.endZ}
                                            onChange={(v, data: InputOnChangeData) =>
                                                this.setState({monitorPara: {...mon, endZ: parseFloat(data.value)}})}
                                        />
                                    }
                                    content={"Z"}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Divider/>
                        <Header as={'h3'}>Variable</Header>
                        <Form.Field>
                            <Dropdown
                                placeholder={"Select variable"}
                                inline
                                selection
                                options={dropdownVariables}
                                mon={mon}
                                value={mon.selVar}
                                onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                    if (data.value) {
                                        this.setState({
                                            monitorPara: {
                                                ...mon,
                                                selVar: data.value.toString()
                                            }
                                        })
                                    }
                                }}
                            />
                        </Form.Field>
                        {/* Used for spacing */}
                        <Form.Field/>
                    </Form>
                    {/*Add a button bar to switch between the modes*/}
                    <Button.Group>
                        <Button primary onClick={this.saveChanges}>Save Changes</Button>
                        <Button secondary onClick={this.cancelChanges}>Undo Changes</Button>
                    </Button.Group>
                    {/* Used for testing */}
                    {/*<br/>{JSON.stringify(this.props.sim.parameters.monitorpara)}*/}
                </Segment>
                <Header as='h2'>Solves</Header>
                {this.buildSolveTable()}

                <div style={{height: '80px'}}/>
          </Container>
        );





    }


}

/**
 * Map from the global state to things we need here
 * @param state
 * @param myProps
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState,myProps:LinkProps ):LinkProps&StateProps {
    return {
        ...myProps,
        solveList:state.solves.solves[myProps.sim.id]
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any,any, any>): DispatchProps {
    return {
        errorResponse:(error:any) => dispatch(errorResponse(error)),
        updateSolvesForSim:(simId:number) => dispatch(solveActions.updateSolvesForSim(simId)),
    };

}

// Determine whether this status can be stopped or not
function isStoppable(status: string) {
    return !(status === "Stopped" || status === "Failed")
}

export default connect (
    mapStateToProps,
    mapDispatchToProps
)(SolveSummary);
