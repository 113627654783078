import React from "react";
import {RouteComponentProps} from "react-router";
import VisusViewer from 'visus-viewer-component';


//Define the expected props
interface IncomingProps extends RouteComponentProps<any> {

}

export const DataSelectorAndViewer = (props:IncomingProps) => {


    //Now
    const dn:number = props.match.params.dataset;


    const urls:string[] = ["http://molniya.sci.utah.edu/mod_visus?action=read_dataset&dataset=foam","http://localhost:8080/mod_visus?action=read_dataset&dataset=texas" ]

    return (
        <VisusViewer key={dn} dataUrl={urls[dn]}/>
        
        );
};
